import VAPI from '../../../http_common';
import { SERVICE_NAMES, HTTP_STATUS } from '../../../app_constants';
import router from "../../../router/router";

function logIn({ commit, dispatch, state }, payload) {
    let data = {
        email: payload.email,
        password: payload.password,
        //origin: navigator.userAgent
    }
    VAPI.post(`/${SERVICE_NAMES.LOGIN}`, data).then((response) => {
        console.log(response.status)
        if (response.status == HTTP_STATUS.OK) {
            commit('setAuthenticated', true);
            commit('setError', { status: false, type: response.status });
            let authorization = response.data.Authorization
            VAPI.get(`/${SERVICE_NAMES.USER}/${data.email}`, { params: {}, headers: { 'Authorization': authorization } }).then((response) => {
                console.log(response.data)
                let current = response.data
                current.AuthCode = authorization
                commit('setCurrentUser', current);
                commit('setError', { status: false, type: response.status });
            }).catch(error => {
                console.error("An error occurred gettin current user: " + error.toString())
                commit('setError', { status: true, type: error.response.status });
            })

        }
        else if (response.status == HTTP_STATUS.NO_CONTENT)
            commit('setError', { status: true, type: response.status });
    }).catch(error => {
        console.log(error)
        console.error("An error occurred login: " + error.toString())
        commit('setError', { status: true, type: error.response.status });
    })
}


async function logInMS({ commit, dispatch, state }, payload) {
    let data = {
        email: payload.email,
        key: payload.key,
        //origin: navigator.userAgent
    }
    console.log(state.authenticated)
    if (!state.authenticated)
        try {
            let response = await VAPI.post(`/${SERVICE_NAMES.LOGINMS}`, data)
            console.log(response.status)
            if (response.status == HTTP_STATUS.OK) {
                commit('setAuthenticated', true);
                commit('setError', { status: false, type: response.status });
                let authorization = response.data.Authorization
                try {
                    let response2 = await VAPI.get(`/${SERVICE_NAMES.USER}/${data.email}`, { params: {}, headers: { 'Authorization': authorization } })
                    console.log(response2.data)
                    let current = response2.data
                    current.AuthCode = authorization
                    commit('setCurrentUser', current);
                    commit('setError', { status: false, type: response2.status });
                } catch (error) {
                    console.error("An error occurred gettin current user: " + error.toString())
                    commit('setError', { status: true, type: error.response.status });
                }

            }
            else if (response.status == HTTP_STATUS.NO_CONTENT)
                commit('setError', { status: true, type: response.status });
        }
        catch (error) {
            console.log(error)
            console.error("An error occurred login: " + error.toString())
            commit('setError', { status: true, type: error.response.status });
        }
}

function logOut({ commit, dispatch }, payload) {
    var redirect = true
    if (payload.redirect == undefined) {
        redirect = true
    }
    else {
        redirect = payload.redirect
    }
    var currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (currentUser == null) {
        commit('setAuthenticated', false);
        commit('setCurrentUser', null);
        commit('setCurrentProject', null);
        commit('setPermissionProjects', []);
        commit('setMenu', []);
        commit('setPolicyStatus', null);
        if (redirect) router.push({ name: 'Home' })
    }
    else {
        VAPI.post(`/${SERVICE_NAMES.LOGOUT}`, null, { headers: { 'Authorization': currentUser.AuthCode } }).then((response) => {
            commit('setAuthenticated', false);
            commit('setCurrentUser', null);
            commit('setCurrentProject', null);
            commit('setPermissionProjects', []);
            commit('setMenu', []);
            commit('setPolicyStatus', null);
            localStorage.clear();
            if (redirect) router.push({ name: 'Home' })
        }).catch(error => {
            console.error("An error occurred logout: " + error.toString())
            if (error.response != undefined)
                commit('setError', { status: true, type: error.response.status });
            commit('setAuthenticated', false);
            commit('setCurrentUser', null);
            commit('setCurrentProject', null);
            commit('setPermissionProjects', []);
            commit('setMenu', []);
            if (redirect) router.push({ name: 'Home' })
        })
    }
}
function clearLoading({ commit }) {
    commit('decreaseLoading');
}

function setLoading({ commit }) {
    commit('increaseLoading');
}

function setCurrentProject({commit, dispatch}, payload){
    commit('setCurrentProject', payload.data);
    dispatch('loadPermissionProjects', {data: payload.data.id, loader: payload.loader})
    dispatch('loadMenu', {data: payload.data.id, loader: payload.loader})
    dispatch('policyStatusQuery', {data: payload.data.id, loader: payload.loader})
}

function loadPermissionProjects({ commit, dispatch, state }, payload) {
    VAPI.get(`/${SERVICE_NAMES.USER}/permissions?user=${state.currentUser?.id}&project=${payload.data}`, {LOADER: payload.loader}).then((response) => {
        console.log(response.status)
        if (response.status == HTTP_STATUS.OK) {
            let permissions = response.data
            commit('setPermissionProjects', permissions);
        }
        else if (response.status == HTTP_STATUS.NO_CONTENT)
            commit('setError', { status: true, type: response.status });
    }).catch(error => {
        console.log(error)
        console.error("An error occurred login: " + error.toString())
        commit('setError', { status: true, type: error.response.status });
    })
}

function loadMenu({ commit, dispatch, state }, payload) {
    VAPI.get(`/${SERVICE_NAMES.GENERAL}/menu?project=${payload.data}&user=${state.currentUser?.id}`, {LOADER: payload.loader}).then((response) => {
        console.log(response.status)
        if (response.status == HTTP_STATUS.OK) {
            let menu = response.data
            commit('setMenu', menu);
        }
        else if (response.status == HTTP_STATUS.NO_CONTENT)
            commit('setError', { status: true, type: response.status });
    }).catch(error => {
        console.log(error)
        console.error("An error occurred login: " + error.toString())
        commit('setError', { status: true, type: error.response.status });
    })
}

function policyStatusQuery({ commit, dispatch, state }, payload) {
    VAPI.get(`/${SERVICE_NAMES.USER}/policy?project=${payload.data}&user=${state.currentUser?.id}`, {LOADER: payload.loader}).then((response) => {
        console.log(response)
        if (response.status == HTTP_STATUS.OK) {
            let policy = response.data
            commit('setPolicyStatus', policy);
        }
        else if (response.status == HTTP_STATUS.NO_CONTENT)
            commit('setError', { status: true, type: response.status });
    }).catch(error => {
        console.log(error)
        console.error("An error occurred login: " + error.toString())
        commit('setError', { status: true, type: error.response.status });
    })
}

export default {
    logIn,
    logOut,
    clearLoading,
    setLoading,
    logInMS,
    setCurrentProject,
    loadMenu,
    policyStatusQuery,
    loadPermissionProjects
}