const state = {
  authenticated: false,
  currentUser: false,
  error: {status:false, type: 0},
  loading: 0,
  project: null,
  menu: [],
  policyStatus: {
    is_valid: true,
  },
  permissions: [],
}

export default state