import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store/index'

import es from './locales/es.json'
import en from './locales/en.json'
import fr from './locales/fr.json'
import pt from './locales/pt.json'

let locale = store?.getters?.getLocale || 'es'
const defaultLocale = 'es'

const languages = {
    es: es,
    en: en,
    fr: fr,
    pt: pt
}

const messages = Object.assign(languages)

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: locale, // set locale
    fallbackLocale: defaultLocale,
    messages, // set locale messages
    silentTranslationWarn: true
})
