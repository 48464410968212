import Vue from 'vue'
import axios from 'axios'
import {URI, HTTP_STATUS} from './app_constants'
import store from './store'
import router from "./router/router";
import {CODES} from "./domain_constants";
import { i18n } from './i18n';
const VAPI = axios.create({
    baseURL: URI,
    timeout: 90000,
    LOADER : true,
  })

  VAPI.defaults.withCredentials = true;

  VAPI.interceptors.request.use(function(config) {
    if (store.getters?.getCurrentUser) {
      config.headers.Authorization = store.getters?.getCurrentUser?.AuthCode
    }
    if(config.LOADER)
      store.dispatch('setLoading');
    return config
  })
  
  VAPI.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  };
  
  VAPI.interceptors.response.use(function(response) {
    if (store?.getters?.isLoading > 0)
      store.dispatch('clearLoading');
    if(response?.data?.code == HTTP_STATUS.UNAUTHORIZED && router.currentRoute.name == "Home") store.dispatch('logOut',{redirect:false})
    else if(response?.data?.code == HTTP_STATUS.UNAUTHORIZED) store.dispatch('logOut',{redirect:true})
    return Promise.resolve(response) 
  }, function(error) {
    if (store?.getters?.isLoading > 0)
      store.dispatch('clearLoading');
    if (error?.response) {
      if(error?.response?.status == HTTP_STATUS.UNAUTHORIZED && router.currentRoute.name == "Home") {
        store.dispatch('logOut',{redirect:false})
        if (error.response.data.code == CODES.NO_VALID_TOKEN_CODE){
          Vue.$toast.warning(i18n.t('message.invalid_token'),);
        }
      }
      else if(error?.response?.status == HTTP_STATUS.UNAUTHORIZED) store.dispatch('logOut',{redirect:true})
      store.commit("setError", {status:true, type: error.response.data.code})
      return Promise.reject(error);
    }
  })
  
export default VAPI